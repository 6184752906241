import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LocRatingMap from './map/loc-rating-map';
import { MapMarkerInfoBox } from './map/map-marker';

/**
 * Assets
 */

/**
 * Components
 */
import Marker from './marker/marker';

const DetailMap = (props) => {
  // Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat']  = props.Latitude;        
  mapItem['lng']  = props.Longitude;
  const mapMarkerImageShowFlag = 0;
  let mapItemHtml = MapMarkerInfoBox(props.Title,mapMarkerImageShowFlag);
  mapItem['html'] = mapItemHtml;
  mapItems.push( mapItem );

    const styles = {
        control: styles => ({ 
            ...styles, 
            backgroundColor: null, 
            border: 0,
            outline: 0,
            boxShadow: 'none',
            color: '#5D9272'
        }),
        option: (styles) => ({
            ...styles
        }),
        placeholder: () => null,
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "#5D9272"
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            color: '#5D9272',
        }),
        indicatorSeparator: () => null,
        singleValue: styles => ({
            ...styles,
            color: '#5D9272',
        })
    }

   

    return (
        <div className="property-location detail-map" data-aos="fade-up">
                <div >
                    <LocRatingMap data={mapItems} />
                </div>
        </div>
    )
}

export default DetailMap
