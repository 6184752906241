import React, { useEffect } from "react";
import { Container } from "react-bootstrap"
import "./ElfsightWidget.scss";

const ElfsightWidget = ({ elfsightCode }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="elfsight-widget-wrap">
        <Container>
            <div class={elfsightCode} data-elfsight-app-lazy></div>
        </Container>
    </div>
  );
};

export default ElfsightWidget;
