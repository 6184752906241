import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./OfficeBanner.scss"
import { AltSiteName } from "../utils";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import TrustPilotLogo from "../trust-pilot-small-logo";
import GoogleRating from '../StaticModules/GoogleRating/GoogleRating';
import $ from 'jquery';
const OfficeBanner = (props) => {
    const officeitem = props.data
    let processedImages = JSON.stringify({});
    if (officeitem?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = officeitem.imagetransforms.Banner_Image_Transforms;
    }
    function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
            scrollTop: $(thishash).offset().top - 30
        }, 1000);
    }

    const handleClick = (val) => {
      
        const element = document.querySelector("#office-details");
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top:
                    element.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    70,
            })
        }
    }
    return (
        <React.Fragment>
            <section className="office-banner">
                <div className="banner-info">
                    <Container>
                        <Row>
                            <Col lg={7}>                                
                                 <h1>
                                    {officeitem?.Name && 'Estate Agents in ' + officeitem.Name }
                                 </h1>
                                <h4>{officeitem.Office_Address}</h4>
                                <div className="box-wrapper"> 
                                    <div className="box-content">
                                        {officeitem.Sales_Phone_Number &&
                                            <div className="box">
                                                <h3>
                                                    Sales:&nbsp;  <a href={`tel:${officeitem.Sales_Phone_Number}`} className="tel">{officeitem.Sales_Phone_Number}</a>
                                                </h3>
                                                <a href="javascript:;" onClick={() => gotohash('#branch-form-section .contact-branch')} className="mail">{officeitem.Sales_Email_Address}</a>
                                            </div>
                                        }
                                        {officeitem.Lettings_Phone_Number &&
                                            <div className="box">
                                                <h3>
                                                    Lettings:&nbsp;   <a href={`tel:${officeitem.Lettings_Phone_Number}`} className="tel">{officeitem.Lettings_Phone_Number}</a>
                                                </h3>
                                                <a href="javascript:;" onClick={() => gotohash('#branch-form-section .contact-branch')} className="mail">{officeitem.Lettings_Email_Address}</a>
                                            </div>
                                        }
                                    </div>
                                    <div className="box-content">
                                        <div className="opening-details box">
                                            <h3>Opening Hours</h3>
                                            <ul className="hours-details">
                                                {officeitem.Office_Opening_Hours && officeitem.Office_Opening_Hours.map((item) => {
                                                    return <>
                                                        <li>{item.Day} : {item.Time}</li> 
                                                    </>
                                                })}
                                            </ul>
                                            <span className="notice">*All calls are forwarded to our customer care centre</span>
                                        </div>
                                      
                                    </div>
                                    {/* <GoogleRating /> */}
                                    <div className="btn-wrapper">
                                        <Link to={`/property-valuation/free-valuation/`} className="btn btn-primary">
                                            Book a valuation
                                        </Link>
                                        <button onClick={handleClick} className="btn btn-secondary">
                                            Contact Office
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className={`banner-img ${officeitem.Name.toLowerCase().replace(/\s/g, '')}`}>
                                    <ImageTransform imagesources={officeitem.Banner_Image.url} renderer="srcSet" imagename="our-offices.Banner_Image.commontile" attr={{ alt: officeitem.Banner_Image.alternativeText ? officeitem.Banner_Image.alternativeText : officeitem.Name + AltSiteName }} imagetransformresult={processedImages} id={officeitem.id} />
                                </div>
                            </Col>

                        </Row>
                    </Container>

                </div>
            </section>
        </React.Fragment>
    )
}
export default OfficeBanner;