import React from 'react';
import { Link } from "gatsby"

const StickyCTA = (props) => {
    return (          
      <React.Fragment>
        <div className="sticky-footer-cta">
            {props.type === "mobile" &&        
            <a href={`tel:${props.number}`} class="btn btn-primary d-md-none">{props.label}</a>
            }
            {props.type === "link" &&        
            <Link to={props.link} class="btn btn-primary d-md-none">{props.label}</Link>
            }
        </div>
      </React.Fragment>
    )
}
export default StickyCTA;