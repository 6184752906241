import React from "react" 
import { Helmet } from "react-helmet";
import BrandLogo from "../../../images/logo.png";

const OfficeSchema = ({ officeData }) => {
   const { title, description, ratingValue, reviewCount } = officeData;
   return (
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "name": "${title}",
            "description": "${description}",
            "brand": {
              "@type": "Brand",
              "name": "Balgores Property Group",
              "image": "${BrandLogo}"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "${ratingValue}",
              "reviewCount": "${reviewCount}",
              "bestRating": "5"
            }
          }`}
        </script>
    </Helmet>
  );
}
export default OfficeSchema