import React from "react";
import { Container, Col, Row } from "react-bootstrap";
  
const BookingBlock = props => {
    return (
        <div className="search-list-block blue-full-width">
        <div className="appraisal-wrap">
        <Container>
            <Row>
                <Col md="12">
                    <div className="appraisal-bar">
                        <div className="left-block">
                            <h2>What’s my property worth?</h2>
                            <p>
                            Selling or Letting? Get a free and up-to-date valuation from one of our experts.
                            </p>
                        </div>
                        <div className="right-block">
                            <a href="/property-valuation/" target="_blank" className="btn">
                                Book your appraisal
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
    </div>
    );
}
export default BookingBlock;