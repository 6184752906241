import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll"; 
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser';
import { AltSiteName } from "../../utils";
import { getSrc } from "gatsby-plugin-image"
import getVideoId from 'get-video-id';

const OfficeBoost = (props) => {
  const officeitem = props.data;


  return (
    <React.Fragment> 
      <section className="about-boost tile-boost-module">
        <div className="about-box">
          <div className="about-block-img">
            <picture className="img-zoom">
              <img src={officeitem.Living_Module.Living_Module_Image.url} alt={officeitem.Living_Module.Living_Module_Image.alternativeText ? officeitem.Living_Module.Living_Module_Image.alternativeText : officeitem.Living_Module.Living_Module_Title + AltSiteName} />            
            </picture>
          </div>
          <Container>
            <Row>
              <Col md="12" className="ms-lg-auto">
                {officeitem.Living_Module.Living_Module_Info &&
                  <div className="about-boost-content">
                    <h2>{officeitem.Name} Council tax bands</h2>
                    <ul className="living-list">
                      {officeitem.Living_Module.Living_Module_Info &&
                        officeitem.Living_Module.Living_Module_Info.map((item) => {
                          return <>
                            <li><span>{item.Info_List_Title}</span><span>{item.Info_List_Value}</span></li> 
                          </>
                        })}
                    </ul>
                    <p>*Please note your exact council tax rate may vary depending on your Parish</p>
                  </div>
                }
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
};
export default OfficeBoost;
