import React from 'react'
const MarkerIcon = () => (
    <svg width="33" height="41" viewBox="0 0 33 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0102 0C7.16806 0 0 7.10032 0 15.8589C0 19.7691 1.43515 23.3426 3.80353 26.1077L16.0102 40.3478L28.2165 26.1073C30.5849 23.3426 32.02 19.7686 32.02 15.8585C32.0205 7.10032 24.8524 0 16.0102 0Z" fill="#1B1434" />
        <path d="M17.1548 21.6515V18.2601C17.1548 18.1102 17.0953 17.9664 16.9893 17.8604C16.8833 17.7544 16.7395 17.6949 16.5896 17.6949H14.3287C14.1788 17.6949 14.0351 17.7544 13.9291 17.8604C13.8231 17.9664 13.7635 18.1102 13.7635 18.2601V21.6515C13.7635 21.8013 13.704 21.9451 13.598 22.0511C13.492 22.1571 13.3483 22.2167 13.1984 22.2167L9.80748 22.2171C9.73324 22.2171 9.65974 22.2025 9.59115 22.1741C9.52257 22.1457 9.46025 22.1041 9.40776 22.0516C9.35527 21.9991 9.31363 21.9368 9.28522 21.8682C9.25681 21.7996 9.24219 21.7261 9.24219 21.6519V15.1194C9.24219 15.0406 9.25864 14.9627 9.2905 14.8907C9.32235 14.8187 9.3689 14.7542 9.42716 14.7012L15.0789 9.56231C15.183 9.46771 15.3186 9.41529 15.4592 9.41528C15.5998 9.41528 15.7354 9.46769 15.8394 9.56228L21.492 14.7012C21.5502 14.7541 21.5968 14.8187 21.6287 14.8907C21.6605 14.9628 21.677 15.0406 21.677 15.1194V21.6519C21.677 21.7261 21.6623 21.7996 21.6339 21.8682C21.6055 21.9368 21.5639 21.9991 21.5114 22.0516C21.4589 22.1041 21.3966 22.1457 21.328 22.1741C21.2594 22.2025 21.1859 22.2171 21.1117 22.2171L17.72 22.2167C17.5701 22.2167 17.4263 22.1571 17.3203 22.0511C17.2144 21.9451 17.1548 21.8013 17.1548 21.6515V21.6515Z" stroke="white" stroke-width="1.11304" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
const APPIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 16.875L15 3.125C15 2.43464 14.4404 1.875 13.75 1.875L6.25 1.875C5.55964 1.875 5 2.43464 5 3.125L5 16.875C5 17.5654 5.55964 18.125 6.25 18.125H13.75C14.4404 18.125 15 17.5654 15 16.875Z" stroke="#F2F2F2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 5.46875C10.4315 5.46875 10.7812 5.11897 10.7812 4.6875C10.7812 4.25603 10.4315 3.90625 10 3.90625C9.56853 3.90625 9.21875 4.25603 9.21875 4.6875C9.21875 5.11897 9.56853 5.46875 10 5.46875Z" fill="black" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const ShareIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.61929 6.38071 7.5 5 7.5C3.61929 7.5 2.5 8.61929 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.75 18.125C15.1307 18.125 16.25 17.0057 16.25 15.625C16.25 14.2443 15.1307 13.125 13.75 13.125C12.3693 13.125 11.25 14.2443 11.25 15.625C11.25 17.0057 12.3693 18.125 13.75 18.125Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.75 6.875C15.1307 6.875 16.25 5.75571 16.25 4.375C16.25 2.99429 15.1307 1.875 13.75 1.875C12.3693 1.875 11.25 2.99429 11.25 4.375C11.25 5.75571 12.3693 6.875 13.75 6.875Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.6481 5.72681L7.10352 8.64835" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.10352 11.3517L11.6481 14.2732" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const PrintIcon = () => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 6.25V3.125H15.5V6.25" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.5 11.875H5.5V17.1875H15.5V11.875Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.5 13.75H2.6875V7.5C2.6875 6.80964 3.29378 6.25 4.04167 6.25H16.9583C17.7062 6.25 18.3125 6.80964 18.3125 7.5V13.75H15.5" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.1875 9.84375C15.619 9.84375 15.9688 9.49397 15.9688 9.0625C15.9688 8.63103 15.619 8.28125 15.1875 8.28125C14.756 8.28125 14.4062 8.63103 14.4062 9.0625C14.4062 9.49397 14.756 9.84375 15.1875 9.84375Z" fill="#333333"/>
    </svg>
)
const SearchIcon = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 19.5C15.9183 19.5 19.5 15.9183 19.5 11.5C19.5 7.08172 15.9183 3.5 11.5 3.5C7.08172 3.5 3.5 7.08172 3.5 11.5C3.5 15.9183 7.08172 19.5 11.5 19.5Z" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.4984 21.5L17.1484 17.15" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

)
export {
    MarkerIcon,
    APPIcon,
    ShareIcon,
    PrintIcon,
    SearchIcon
}